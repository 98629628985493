



















































































































































































































































































































































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { collection, query, onSnapshot, Unsubscribe } from "firebase/firestore";
import { DateTime } from "luxon";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { vueWindowSizeMixin } from "vue-window-size/option-api";

import { db } from "@/plugins/firebase";
import { MembershipApplicationForm } from "@/types/MembershipApplicationForm";
import { MOBILE_THRESHOLD } from "@/utils/constants";
import MemberApplicationFormRegion from "@/components/member/ApplicationFormRegion.vue";

const isUrl = (value: string) =>
  (!value || value.length === 0) ||
  value.startsWith("http://") ||
  value.startsWith("https://");

const isValidDate = (value: string) => {
  const date = DateTime.fromFormat(value, "yyyy-LL-dd");
  return date.isValid;
};

const BLANK_FORM: MembershipApplicationForm = {
  first_name_en: "",
  last_name_en: "",
  first_name_zh: "",
  last_name_zh: "",
  date_of_birth: "",
  first_4_hkid: "",
  nickname: "",

  address_1: "",
  address_2: "",
  city: "",
  country: "",

  tel_home: "",
  tel_work: "",
  tel_mobile: "",
  fax: "",
  email: "",
  alternative_email: "",
  year_of_admission: undefined,
  first_class: "",
  year_of_departure: undefined,
  last_class: "",
  hkcee_hkdse_year: undefined,
  profession_id: undefined,

  alias_choice_1: undefined,
  alias_choice_2: undefined,
  apply_for_email_alias: false,

  social_profile: "",
};

declare module "vue/types/vue" {
  interface Vue {
    fetch: () => Promise<void>;
  }
}

const MAX_DATE_STRING: string = DateTime.now()
  .toFormat("yyyy-LL-dd");

const EARLIEST_YEAR = 1955;
const CURRENT_YEAR = DateTime.now().year;

export default Vue.extend({
  props: {
    mode: {
      type: String,
      default: "add",
    },
  },
  name: "MemberApplicationForm",
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  components: {
    MemberApplicationFormRegion,
  },
  computed: {
    ...mapGetters(["professions", "countries"]),
    logoSize(): number {
      return this.$windowWidth > MOBILE_THRESHOLD ? 40 : 20;
    },
    profileImageUrl(): string | null {
      if (!this.file) return this.remoteImagePath;

      return URL.createObjectURL(this.file);
    },
    isProfileImageValid(): boolean {
      if (this.remoteImagePath) return true;

      return !!this.file;
    },
    file: {
      get(): File | undefined {
        return this.internalFile;
      },
      set(v: File | undefined): void {
        // get file size
        if (!v) return;
        const size = v.size;
        const LIMIT = 50 * 1024 * 1024;
        if (size <= LIMIT) {
          this.internalFile = v;
        } else {
          this.displayMessage(
            "Your profile image is too large. Please choose one smaller than or equal to 50MB."
          );
        }
      },
    },
    years(): Array<string> {
      const results = [""] as Array<string>;

      let year = CURRENT_YEAR;
      while (year >= EARLIEST_YEAR) {
        results.push(`${year}`);
        year--;
      }

      return results;
    },
    editModeDisabled(): boolean {
      return this.mode === "edit";
    },
    applyForEmailAlias: {
      get(): boolean {
        return this.editedItem.apply_for_email_alias;
      },
      set(v: boolean): void {
        const orgValue = this.editedItem.apply_for_email_alias;
        this.editedItem.apply_for_email_alias = v;
        if (orgValue && !v) {
          this.editedItem.alias_choice_1 = undefined;
          this.$v?.editedItem?.alias_choice_1?.$reset();
          this.editedItem.alias_choice_2 = undefined;
          this.$v?.editedItem?.alias_choice_2?.$reset();
        }
      },
    },
    dateOfBirth: {
      get(): string {
        if (this.editedItem.date_of_birth) {
          const date = DateTime.fromFormat(
            this.editedItem.date_of_birth,
            "yyyy-LL-dd"
          );
          if (date.isValid) {
            this.editedItem.date_of_birth
          } else {
            return '';
          }
        }
        return '';
      },
      set(value: string): void {
        console.log('dateOfBirth Setter')
        if (!value) return;
        const date = DateTime.fromFormat(
          value,
          "yyyy-LL-dd"
        );
        console.log(date.isValid)
        if (date.isValid) {
          this.editedItem.date_of_birth = value;
        }
      },
    }
  },
  data: () => {
    return {
      email: "",
      password: "",
      fields: new Array<any>(),
      editedItem: {
        ...BLANK_FORM,
      },
      modal: false,
      unsubscribe: undefined as Unsubscribe | undefined,
      loading: false,
      applicationId: null,
      processing: false,
      maxDate: MAX_DATE_STRING,
      internalFile: undefined as File | undefined,
      remoteImagePath: null as string | null,
      streams: ["", "Arts", "Science", "Social Science", "Business"],
      show: false,
      askAdminToModify: "Please Contact Admin to Amend",
    };
  },
  methods: {
    ...mapActions(["displayMessage"]),
    async getParams(): Promise<MembershipApplicationForm> {
      this.$v.$touch();
      if (this.$v.$invalid) {
        throw "FORM_INVALID";
      }

      let result = { ...this.editedItem };
      if (this.mode === "edit") {
        if (result.stream && !result.stream.length) {
          delete result.stream;
        }
      }

      return result;
    },
    async getChineseName(): Promise<{
      first_name_zh: string;
      last_name_zh: string;
      nickname: string;
    }> {
      return {
        first_name_zh: this.editedItem.first_name_zh,
        last_name_zh: this.editedItem.last_name_zh,
        nickname: this.editedItem.nickname,
      };
    },
    async getResidentialAddress(): Promise<{
      address_1: string;
      address_2: string;
      city: string;
      country: string;
    }> {
      (this.$v.residential_address as any).$touch();

      if (this.$v.residential_address.$invalid) {
        throw "FORM_INVALID";
      }

      return {
        address_1: this.editedItem.address_1,
        address_2: this.editedItem.address_2,
        city: this.editedItem.city,
        country: this.editedItem.country,
      };
    },
    async getContactInfo(): Promise<{
      tel_mobile: string;
      tel_home?: string;
      tel_work?: string;
      fax?: string;
      alternative_email?: string;
    }> {
      (this.$v.contact_information as any).$touch();

      if (this.$v.contact_information.$invalid) {
        throw "FORM_INVALID";
      }

      return {
        tel_mobile: this.editedItem.tel_mobile,
        tel_home: this.editedItem.tel_home,
        tel_work: this.editedItem.tel_work,
        fax: this.editedItem.fax,
        alternative_email: this.editedItem.alternative_email,
      };
    },
    async getEducationAndQualifications(): Promise<{
      year_of_admission?: string;
      first_class?: string;
      stream?: string;
      year_of_departure?: string;
      last_class?: string;
      post_secondary_institution?: string;
      academic_professional_qualification?: string;
      profession_id?: string | number;
      occupation?: string;
      social_profile?: string;
    }> {
      (this.$v.education_qualifications as any).$touch();

      if (this.$v.education_qualifications.$invalid) {
        throw "FORM_INVALID";
      }

      return {
        year_of_admission: this.editedItem.year_of_admission,
        first_class: this.editedItem.first_class,
        stream: this.editedItem.stream,
        year_of_departure: this.editedItem.year_of_departure,
        last_class: this.editedItem.last_class,
        post_secondary_institution: this.editedItem.post_secondary_institution,
        academic_professional_qualification:
          this.editedItem.academic_professional_qualification,
        profession_id: this.editedItem.profession_id,
        occupation: this.editedItem.occupation,
        social_profile: this.editedItem.social_profile,
      };
    },
    getFile(): File | undefined {
      return this.file;
    },
    setItem(item: MembershipApplicationForm): void {
      this.editedItem = item;
    },
    setImage(path: string): void {
      this.remoteImagePath = path;
    },
    getRemoteImagePath(): string | null {
      return this.remoteImagePath;
    },
    async fetch() {
      const q = query(collection(db, "membershipFormFields"));
      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fields = new Array<any>(); // = [];
        querySnapshot.forEach((doc) => {
          fields.push(doc.data());
        });

        this.fields = fields;
      });
    },
    getLabel(key: string): string {
      const field = this.fields.find((f) => f.id === key);
      if (field) {
        return field.display;
      }
      return key;
    },
    getExplanation(key: string): string {
      const field = this.fields.find((f) => f.id === key);
      if (field) {
        return `e.g. ${field.explanation}`;
      }
      return "";
    },
    getErrors(name: string, model: any, debug = false): Array<string> {
      const errors = new Array<string>();

      if (name === "cannotModifyInEdit") {
        // errors.push(this.askAdminToModify);
        return errors;
      }

      if (!model) return errors;
      if (!model.$dirty) return errors;

      if (debug) {
        console.log(model);
      }

      switch (name) {
        case "email":
          !model.email && errors.push("Must be valid email");
          !model.required && errors.push("Email is required");
          break;
        case "optionalEmail":
          !model.email && errors.push("Must be valid email");
          break;
        case "essential":
          !model.required && errors.push("This field is required");
          break;
        case "isUrl":
          !model.isUrl && errors.push("Must be a valid URL");
          break;
        case "isDateValid":
          !model.required && errors.push("This field is required");
          model.required && !model.isValidDate && errors.push("Date auto-filled is invalid, please input again");
          break;

        default:
          break;
      }
      return errors;
    },
    getErrorName(name: string): string {
      if (this.editModeDisabled) return "cannotModifyInEdit";

      return name;
    },
    onFileButtonClick(): void {
      let fileUpload = document.getElementById("fileUploader");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    dragFile(e): void {
      if (
        e &&
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length
      ) {
        const file: File = e.dataTransfer.files[0];

        const type: string = file.type.toLowerCase();
        if (
          type.includes("png") ||
          type.includes("jpg") ||
          type.includes("jpeg") ||
          type.includes("gif")
        ) {
          this.file = file;
        } else {
          this.displayMessage("The file type is not supported.");
        }
      }
    },
  },
  mixins: [validationMixin, vueWindowSizeMixin()],
  mounted() {
    this.fetch();
  },
  validations(): any {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return {
      editedItem: {
        first_name_en: {
          required,
        },
        last_name_en: {
          required,
        },
        date_of_birth: {
          required,
          isValidDate,
        },
        first_4_hkid: {
          required,
        },
        address_1: {
          required,
        },
        address_2: {
          required,
        },
        city: {
          required,
        },
        country: {
          required,
        },
        tel_mobile: {
          required,
        },
        email: {
          required,
          email,
        },
        alternative_email: {
          email,
        },
        year_of_departure: {
          required,
        },
        hkcee_hkdse_year: {
          required,
        },
        social_profile: {
          isUrl,
        },
        alias_choice_1: {
          required: requiredIf(function () {
            return !self.editModeDisabled && self.applyForEmailAlias;
          }),
        },
        alias_choice_2: {
          required: requiredIf(function () {
            return !self.editModeDisabled && self.applyForEmailAlias;
          }),
        },
      },
      personal_particulars: [
        "editedItem.first_name_en",
        "editedItem.last_name_en",
        "editedItem.date_of_birth",
        "editedItem.first_4_hkid",
      ],
      residential_address: [
        "editedItem.address_1",
        "editedItem.address_2",
        "editedItem.city",
        "editedItem.country",
      ],
      contact_information: [
        "editedItem.tel_mobile",
        "editedItem.email",
        "editedItem.alternative_email",
      ],
      education_qualifications: [
        "editedItem.year_of_departure",
        "editedItem.hkcee_hkdse_year",
        "editedItem.social_profile",
      ],
      email_alias_service: [
        "editedItem.alias_choice_1",
        "editedItem.alias_choice_2",
      ],
    };
  },
});
